import Vue from 'vue';
import i18n from './i18n'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isToday from 'dayjs/plugin/isToday'

dayjs.extend(localizedFormat)
dayjs.extend(isTomorrow)
dayjs.extend(isToday)

import 'dayjs/locale/en'
import 'dayjs/locale/es'

Vue.filter('date', (date, format = 'LLL') => {
  return dayjs(date).locale(i18n.locale).format(format)
})