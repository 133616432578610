<template>
  <router-view/>
</template>

<script>

export default {
  name: "App",
  metaInfo() {
    return {
      title: 'Track',
      titleTemplate: '%s · ' + this.$t('title')
    }
  } 
}
</script>